<template>
    <b-row>
        <b-col>
        <zw-select-group v-model="inputVal.shipping"
                         :options="Object.values(getShippingMethods())"
                         name="shipping"
                         disable-label
                         @change="savePosition('shipping',inputVal)"
                         :readonly="readOnly"
                         size="sm"
        ></zw-select-group>
        </b-col>

        <b-col v-if="['Warenpost','Warenpost International'].includes(inputVal.shipping)">
            <b-form-group :label="$t('offering.position.column.shipping_premium')">
                <b-form-checkbox
                    v-model="inputVal.shipping_premium"
                    name="shipping_premium"
                    :value="1"
                    :unchecked-value="0"
                    size="sm"
                    :disabled="readOnly"
                    @change="savePosition('shipping_premium',inputVal)"
                >
                </b-form-checkbox>
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'ShippingColumn',
    props: {
        value: [Object],
        savePosition: [Function],
        readOnly: [Boolean],
    },
    methods: {
        ...mapGetters('CommonData', ['getShippingMethods']),
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>